import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { privatePost, privatePut } from "../../../services/privateRequest";
import { POST, POSTS, PUT } from "../../../services/apiEndpoints";
import { toast } from "react-toastify";
import Required from './../../shared/Required';
import { display } from "@mui/system";


const AddPostModal = ({
  open,
  handleClose,
  fetchPosts,
  firstName,
  groupsData,
  clientsData,
  editPostData,
  edit,
  postId,
  setFlag,
  flag,
}) => {
  const initialValues = {
    post: "",
    client: "",
    group: "",
    developer: ""
    // location:  ""
  }
  const [isEditDropdown, setIsEditDropdown] = useState(false);
  const [postData, setPostData] = useState(initialValues);


  const groupsOptions = useMemo(() => {
    return groupsData?.map(group => ({ label: group?.title, value: group?._id }))
  }, [groupsData])

  const clientOptions = useMemo(() => {
    return clientsData?.map(client => ({ label: client?.clientName, value: client?._id }))
  }, [clientsData])

 

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "#fff",
    // border: '2px solid #000',
    borderRadius: "5px",
    boxShadow: 24,
    padding: "30px",
  };

  const handleChange = (event, key) => {
    setPostData({ ...postData, [key]: event.target.value });
  };

  const handleEditOpen = () => {
    setIsEditDropdown(!isEditDropdown);
  };

  const validateImg = (file) => {
    if (
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "image/png"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const validateFile = (file) => {
    if (
      file.type === "application/pdf" ||
      file.type === "application/msword" ||
      file.type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return true;
    } else {
      return false;
    }
  };

  // const validatePost = () => {
  //   const temp = { ...postData }
  //   delete temp.client
  //   const flag = typeof (Object.values(temp).find(value => value === "")) !== "undefined"
  //   if (flag) return false;
  //   return true;
  // };

  const validatePost = () => {
    const temp = { ...postData };
    const isPostValid = temp.post.trim() !== "";
    const isDeveloperValid = temp.developer.trim() !== "";
    const isAllRequiredFieldsFilled = Object.values({ post: temp.post, developer: temp.developer }).every(
      (value) => value.trim() !== ""
    );
    return isPostValid && isDeveloperValid && isAllRequiredFieldsFilled;
  };
  

  const createPost = async () => {
    const payload = {
      content: postData.post || editPostData?.content,  
      groupId: [postData.group || editPostData?.groupId?.[0]?._id],  
      developer: postData.developer || editPostData?.developer,  
      client: [postData.client || editPostData?.client?.[0]?._id],  
    };
    if (postId) {
      privatePut(POST, postId, 
      //   {
      //   // title: "This is socials Nimap post",
      //   content: postData.post,
      //   groupId: [postData.group],
      //   developer:postData.developer,
      //   client:[postData.client],
      //   // ...postData.client ? clientData : {}

      //   //   location: postData.location, 
      // }
      payload
    )
        .then((res) => {
          toast.success(res.data.message);
          handleClose()
          setFlag(!flag)
          setPostData(initialValues)
          fetchPosts("", true)
        });
    } else {
      privatePost(POST, 
      //   {
      //   // title: "This is socials Nimap post",
      //   content: postData.post,
      //   groupId: [postData.group],
      //   developer:postData.developer,
      //   client:[postData.client],
      //   // ...postData.client ? clientData : {}
      //   //   location: postData.location, 
      // }
      payload
    )
        .then((res) => {
          toast.success(res.data.message);
          handleClose()
          setFlag(true)
          setPostData(initialValues)
          fetchPosts("", true)
        });

      // setPostData({
      //   post: "",
      //   client: "",
      // });
    }
  };

  useEffect(() => {
    if (edit && editPostData) {
      setPostData({
        post: editPostData.content || "",
        client: editPostData.client?.[0]?._id || "",
        group: editPostData.groupId?.[0]?._id || "",
        developer: editPostData.developer || "",
      });
    } else {
      setPostData(initialValues);
    }
  }, [edit, editPostData, open]);

  return (
    <Modal
      open={open}
      onClose={() => {
        setPostData(initialValues)
        handleClose()
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box style={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {edit ? 'Edit' : 'Add'}  Post
        </Typography>
        <div className="form-container">
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <p className="input-field-label">Questions <Required /></p>
            <TextField
              rows={8}
              variant="outlined"
              placeholder={`What's on your mind ${firstName || ""}?`}
              multiline
              // maxRows={10}
              InputProps={{
                sx: {
                  borderRadius: "32px",
                  // width: "90%",
                  fontSize: "10px",
                  paddingLeft: "1rem",
                  backgroundColor: "white",
                },
                // onFocus: handleFocus,
                // onBlur: handleBlur
              }}
              fullWidth
              defaultValue={postData.post || editPostData?.content}
              onChange={(e) => handleChange(e, "post")}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <p className="input-field-label">Groups/Technologies <Required /></p>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={postData.group || editPostData?.groupId?.[0]?._id || ""}
                displayEmpty
                onChange={(e) => handleChange(e, "group")}
                sx={{
                  height: "30px",
                  fontSize: "10px",
                  borderRadius: "24px",
                }}
              >
                <MenuItem
                  sx={{
                    fontSize: "11px",
                    maxWidth: "400px",
                    whiteSpace: "pre-line",
                  }}
                  value={""}
                >
                  Select Group
                </MenuItem>
                {groupsOptions?.map(({ label, value }) => {
                  return (
                    <MenuItem
                      sx={{
                        fontSize: "11px",
                        maxWidth: "400px",
                        whiteSpace: "pre-line",
                      }}
                      value={value}
                    >
                      {label || ""}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <p className="input-field-label">Client  <Required /></p>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={postData?.client || editPostData?.client?.[0]?._id || ""}
                displayEmpty
                onChange={(e) => handleChange(e, "client")}
                sx={{
                  height: "30px",
                  fontSize: "10px",
                  borderRadius: "24px",
                }}
              >
                <MenuItem
                  sx={{
                    fontSize: "11px",
                    maxWidth: "400px",
                    whiteSpace: "pre-line",
                  }}
                  value={""}
                >
                  Select Client
                </MenuItem>
                {clientOptions?.map(({ label, value }) => {
                  return (
                    <MenuItem
                      sx={{
                        fontSize: "11px",
                        maxWidth: "400px",
                        whiteSpace: "pre-line",
                      }}
                      value={value}
                    >
                      {label || ""}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <p className="input-field-label">Developer  <Required /></p>
            <FormControl>
              <TextField
                size="small"
                variant="outlined"
                placeholder={"Enter Developer Name"}
                multiline
                maxRows={10}
                InputProps={{
                  sx: {
                    borderRadius: "32px",
                    fontSize: "10px",
                    paddingLeft: "1rem",
                    backgroundColor: "white",
                  },
                  // onFocus: handleFocus,
                  // onBlur: handleBlur
                }}
                fullWidth
                defaultValue={postData.developer || editPostData?.developer}
                onChange={(e) => handleChange(e, "developer")}
              />
            </FormControl>
          </div>
          {/* <div style={{display: "flex", flexDirection: "column", gap: "5px"}}>
            <p className="input-field-label">Location <Required/></p>
            <TextField
                size="small"
                variant="outlined"
                placeholder={"Enter Location"}
                multiline
                maxRows={10}
                InputProps={{
                sx: {
                    borderRadius: "32px",
                    // width: "90%",
                    fontSize: "10px",
                    paddingLeft: "1rem",
                    backgroundColor: "white",
                },
                // onFocus: handleFocus,
                // onBlur: handleBlur
                }}
                fullWidth
                value={postData.location}
                onChange={(e) => handleChange(e, "location")}
            />
           </div> */}
          <div className="buttons-container">
            <Button
              onClick={()=>{
                createPost()
                handleClose()
              }}
              disabled={ !validatePost()}
              variant="contained"
            
            >
              {edit ? 'Update' : 'Create'}  Post
            </Button>
            <Button
              onClick={() => {
                setPostData(initialValues)
                handleClose()
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default AddPostModal;
