import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Menu from "../../../routes/routesJSON/Menu.json";
import "./Sidebar.css";
import SettingsIcon from '@mui/icons-material/Settings';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import ForumIcon from '@mui/icons-material/Forum';
import HistoryIcon from '@mui/icons-material/History';
import defaultProfile from "../../../assets/images/default-profile.png";
import { toast } from "react-toastify";
import { getLoggedInUserDetails } from "../../../utils/utils";
import { privateGet } from "../../../services/privateRequest";
import { ROLE } from "../../../services/apiEndpoints";

const Sidebar = ({ setToken }) => {
  const loggedInUser = getLoggedInUserDetails();
  const userName = loggedInUser?.name;
  const [userRole, setUserRole] = useState("Software Developer");
  const [showSubMenuItems, setShowSubMenuItems] = useState(Menu.map((item) => false));

  const iconComponents = {
    "your feed": <FeedOutlinedIcon fontSize="small" />,
    "account settings": <SettingsIcon fontSize="small" />,
    "groups": <GroupOutlinedIcon fontSize="small" />,
    "discussion forum": <ForumIcon fontSize="small" />,
    "client": <PeopleOutlineIcon fontSize="small" />,
    "logout": <LogoutOutlinedIcon fontSize="small" />,
  };

  const getUserRole = async () => {
    if (!loggedInUser?.role) {
      return;
    }
    const res = await privateGet(`${ROLE}/${loggedInUser?.role}`);
    if (res?.data?.data?.role) {
      setUserRole(res?.data?.data?.role);
    }
  };

  useEffect(() => {
    getUserRole();
  }, []);

  return (
    <div className={`sidebar`}>
      <ul className="sidebar__ul sidebar__ul--1">
        <li className="sidebar__ul--li-head">
          <div className="sidebar-profile-details">
            <div>
              <img className="profile-image" src={defaultProfile} alt="no-profile-pic" />
            </div>
            <div className="profile-info">
              <p className="profile-name">{userName}</p>
              <p className="profile-role">{userRole}</p>
            </div>
          </div>
        </li>
      </ul>
      <hr className="sidebar-hr" />
      <ul className={`sidebar__ul sidebar__ul--2`}>
        {Menu?.map((header) => (
          <div key={header.id}>
            {header.masterName === "home" && <li className="sidebar__ul--li-nav-1"></li>}
            <div className="sub-menu-items">
              {(showSubMenuItems[header.id - 1] || !(header.id - 1)) &&
                header.masterPanel?.map((panel) => (
                  (panel?.name !== "Login Logs" || userRole === "admin") && (
                    <li key={panel.id} className={`sidebar__ul--li ${(panel.id === 1) && "first"}`}>
                      <NavLink to={`${panel.link}`} className={`sidebar__ul--li-nav ${(panel.id === 1) && "first"}`}>
                        <div className={`sidebar-subitems-icons ${(panel.id === 1) && "first"}`}>
                          <span>{iconComponents[panel.name] || (panel.name === "Login Logs" && <HistoryIcon fontSize="small" />)}</span>
                        </div>
                        <div className="menu-name">
                          <span>{panel.name}</span>
                        </div>
                      </NavLink>
                    </li>
                  )
                ))}
              <li className={`sidebar__ul--li ${"last"}`}>
                <NavLink
                  className="sidebar__ul--li-nav"
                  onClick={() => {
                    setToken(null);
                    localStorage?.removeItem("token");
                    toast.success("Logout Successful");
                  }}
                >
                  <div className={`sidebar-subitems-icons last`} style={{ color: "rgba(255, 255, 255, 0.66)" }}>
                    <span>{iconComponents["logout"]}</span>
                  </div>
                  <div className="menu-name" style={{ backgroundColor: "#fff", color: "#333333" }}>
                    <span>Logout</span>
                  </div>
                </NavLink>
              </li>
            </div>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
