import React from 'react';
import { Navigate} from 'react-router-dom';
import { getToken } from '../utils/utils';

const PublicRoute = ({component: Component , token , setToken}) => {
    return (
        !getToken() ? <Component setToken={setToken} /> : <Navigate to="/your-feed" /> 
    )
}
 
export default PublicRoute;