import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import './LoginLogs.css';
import { LOGGER } from '../../../services/apiEndpoints';
import { privateGet } from '../../../services/privateRequest';
import Spinner from '../../shared/Spinner/Spinner';
import { getLoggedInUserDetails } from '../../../utils/utils';
import moment from 'moment';
import { useOutletContext } from 'react-router';
import _ from 'lodash'; // Import lodash for debouncing

const loggedInUser = getLoggedInUserDetails();

const LoginLogs = () => {
    const [logsData, setLogsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDataEmpty, setIsDataEmpty] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMoreLogs, setHasMoreLogs] = useState(true);
    const [searchValue] = useOutletContext();

    const handlePostTime = (time) => {
        return moment(time).format('MMMM Do YYYY, h:mm:ss a');
    };

    const fetchLogs = async (search) => {
        if (isLoading) return; // Prevent fetching if already loading
        setIsLoading(true);

        try {
            const url = search ? `${LOGGER}?search=${search}` : `${LOGGER}?page=${page}&limit=10`;
            const resp = await privateGet(url);

            if (resp?.data?.data) {
                const newLogs = resp.data.data;
                if (page === 1) {
                    setLogsData(newLogs); // Initial load or search reset
                } else {
                    setLogsData((prevLogs) => [...prevLogs, ...newLogs]); // Append more logs
                }
                setIsDataEmpty(newLogs.length === 0);
                setHasMoreLogs(newLogs.length === 10); // Expecting 10 logs per page
            } else {
                setIsDataEmpty(true);
                setHasMoreLogs(false);
            }
        } catch (error) {
            console.error('Error fetching logger data:', error);
            setIsDataEmpty(true);
            setHasMoreLogs(false);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const searchTimeout = setTimeout(() => {
            setPage(1); // Reset page to 1 when search value changes
            fetchLogs(searchValue);
        }, 1000);

        return () => {
            clearTimeout(searchTimeout);
        };
    }, [searchValue]);

    const handleScroll = _.debounce(() => {
        if (
            window.scrollY + window.innerHeight >= document.documentElement.scrollHeight - 100 &&
            !isLoading &&
            hasMoreLogs
        ) {
            setPage((prevPage) => prevPage + 1); // Increment page by 1
        }
    }, 200); // Adjust debounce delay as needed (e.g., 200ms)

    useEffect(() => {
        if (page > 1 || searchValue) fetchLogs(searchValue);
    }, [page]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <p className="groups-heading">
                <Typography variant="h5" component="h5"><b>Logs</b></Typography>
            </p>
            <div className='main-feed'>
                <Box
                    sx={{
                        borderRadius: '6px',
                        boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.2)',
                        fontSize: '.6rem',
                        backgroundColor: 'white',
                        width: 'calc(100vw - 438px)',
                    }}
                >
                    <TabContext value="1">
                        <TabPanel value="1" sx={{ padding: '.75rem' }}></TabPanel>
                    </TabContext>

                    <div>
                        <Stack spacing={2}>
                            <div className='all-posts-cards'>
                                {isDataEmpty ? (
                                    <p className='no-posts-message'>No Logs</p>
                                ) : (
                                    logsData?.map((item, index) => (
                                        <Box
                                            key={item._id}
                                            className='render-post'
                                        >
                                            <Box className='post-profile'>
                                                <Grid container spacing={2}>
                                                    <Grid item>
                                                        <div className='post-profile-avatar'>
                                                            {item?.userId?.name
                                                                ?.split(' ')
                                                                .map((i) => i[0]?.toUpperCase())
                                                                .join('') || 'U'}
                                                        </div>
                                                    </Grid>
                                                    <Grid item>
                                                        <Box className='profile-details' sx={{ paddingBottom: '0.15rem', userSelect: 'none' }}>
                                                            {`${item?.userId?.name}`}
                                                        </Box>
                                                        <Box className='update-details' sx={{ paddingBottom: '0.15rem', userSelect: 'none' }}>
                                                            {`${item?.userId?.email}`}
                                                        </Box>
                                                        <Box className='update-details' sx={{ userSelect: 'none' }}>
                                                            {handlePostTime(item.createdAt)}
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <Box className='line-container'>
                                                <p></p>
                                            </Box>
                                        </Box>
                                    ))
                                )}
                            </div>
                        </Stack>

                        {isLoading && (
                            <div className='align-spinner'>
                                <Spinner />
                            </div>
                        )}
                    </div>
                </Box>
            </div>
        </>
    );
};

export default LoginLogs;
