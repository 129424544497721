import "./HomeLayout.css"
import React, { useState, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Spinner from "../shared/Spinner/Spinner";

// const Sidebar = lazy(() => import("./../components/dashboard/Sidebar"))
// const Navbar = lazy(() => import("./../components/dashboard/Navbar"))
// const ProfileModal = lazy(() => import("../components/dashboard/modal/ProfileModal"))

import Sidebar from '../shared/SideBar/Sidebar';
import Navbar from '../shared/Navbar/Navbar';
import GroupIcon from '../../assets/svg/Group.svg'
import { useEffect } from "react";


const HomeLayout = ({ setToken }) => {
    const [hideSidebar, sethideSidebar] = useState(false);
    const [openChangePwd, setOpenChangePwd] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        localStorage.getItem("token") && setToken(localStorage.getItem("token"))
    }, [])

    const closePassModal = () => {
        setOpenChangePwd(false)
    }
    const handleHideSidebar = () => {
        sethideSidebar(!hideSidebar)
    }
    const [openModal, setOpenModal] = useState(false);

    return (
        <div className='home-layout'>
            {/* {openModal && <ProfileModal setOpenModal={setOpenModal} setOpenChangePwd={setOpenChangePwd} setToken={setToken} username={JSON.parse(localStorage.getItem("user"))?.email}/>} */}
            {/* {openChangePwd && <ChangePwd openChangePwd={openChangePwd} closeModal={closePassModal} />} */}
            <div className='homepage-container'>
                <Navbar searchValue={searchValue} setSearchValue={setSearchValue}/>
                <div className="homepage-content">
                    <div>

                    <div className="side-panel">
                        <Sidebar setToken={setToken} hideSidebar={hideSidebar} handleHideSidebar={handleHideSidebar} />
                        {/* <div>
                            <div className="side-group-card">
                                <div className="side-group-tag">
                                    <div className="side-group-icon"><img src={GroupIcon} /></div>
                                    <span className="side-group">My Groups</span>
                                </div>
                                <div className="side-group-details">
                                    <p>Business Anaylst</p>
                                    <span>11 Members</span>
                                </div>
                            </div>
                        </div> */}

                    </div>
                    </div>

                    <div className="outlet-div">
                        <Suspense fallback={<div style={{
                            display: "flex",
                            height: "70vh",
                            width:"50vw",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <Spinner />
                        </div>}>
                            <Outlet context={[searchValue]}/>
                        </Suspense>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeLayout;