import React, {useState} from "react";
import { useNavigate } from "react-router";
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import NimapLogo from "../../../assets/svg/Nimap-Logo.svg"
// import profileImg from '../../../assets/images/profilePic.jpg'
import defaultProfile from "../../../assets/images/default-profile.png"
import "./Navbar.css"

const Navbar = ({searchValue, setSearchValue}) => {
  const [notificationCount, setNotificationCount] = useState(7);

  const navigate = useNavigate()
  return (
    <nav
      className="navbar navbar-expand-lg"
    > 
      <div className="collapse navbar-collapse" id="navbarNav" style={{display: "flex", justifyContent:"space-between"}}>
        <div>
          <img src={NimapLogo} className="nimap-logo" alt="nimap-logo" onClick={() => {
            navigate("/")
          }} />
        </div>
        <div style={{position: "relative"}}> 
          <input 
            className="navbar-searchbox" 
            type="text" 
            placeholder="Search in Nimap..." 
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value)
            }}
          />
          <div className="navbar-search-icon">
              <SearchOutlinedIcon fontSize="small"/>
          </div>
        </div>
        <div className="right-div">
          {/* <div className="notification-icon">
            <div className="notification-count">{notificationCount}</div>
            <NotificationsNoneOutlinedIcon fontSize="medium"/>
          </div> */}
          <div className="vertical-line"></div>
          <div>
            <img className="navbar-profile-image" alt="profile" src={defaultProfile}/>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
