import axios from "axios";
// import { BASE_URL } from './../apiEndpoints/index';
import { toast } from "react-toastify";

const privateRequest = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
})
 
const requestHandler = (request) => {
    const token = localStorage.getItem("token") ? localStorage.getItem("token"): ""
    request.headers.Authorization = `Bearer ${token}`
    return request
}

const responseHandler = (response) => {
    toast.success(response.message)
    return response
}

const clearToken = () => {
    localStorage.removeItem("token")
}

const responseErrorHandler = (error) => {
    if(error.response){
        const {status, data} = error.response
        // if(status === 401){
        //     clearToken()
        //     window.location.href = "/"
        //     toast.warn("Token expired, please login")
        // }else if(status === 400){
        //     toast.error(data.message ? data.message : "Invalid Value/ Bad Request")
        // }else if(status === 403){
        //     toast.error(data.message ? data.message : "Access Denied/ Forbidden")
        // }else if(status === 404){
        //     toast.error(data.message ? data.message : "Item doesn't exist")
        // }else if(status === 405){
        //     toast.error(data.message ? data.message : "Invalid Request")
        // }else if(status === 504){
        //     toast.error(data.message ? data.message : "Network Error")
        // }else if(status > 500){
        //     toast.error(data.message ? data.message : "Server Error")
        // }
        if(status > 500){
            toast.error(data.message ? data.message: "Server Error")
        }
        switch (status) {
            case 401:              
                toast.warn("Token expired, please login")
                clearToken()
                window.location.href = "/"
                break;
            case 400:
                toast.error(data.message ? data.message : "Invalid Value/ Bad Request")
                break
            case 403:
                toast.error(data.message ? data.message : "Access Denied/ Forbidden")
                break
            case 404:
                toast.error(data.message ? data.message : "Item doesn't exist")
                break
            case 405:
                toast.error(data.message ? data.message : "Invalid Request")
                break
            case 504:
                toast.error(data.message ? data.message : "Network Error")
                break
            default:
                break;
        }
    }    
    else{
        toast.error(error.message)
    }
}

const errorHandler = (error) => {
    return Promise.reject(error)
}

privateRequest.interceptors.request.use(
    requestHandler,
    errorHandler
)

privateRequest.interceptors.response.use(
    responseHandler,
    responseErrorHandler
)

export const privateGet = (endPoint) => {
    console.log('endPoint');
    return privateRequest.get(endPoint)

}

export const privatePost = (endPoint, data) => {
    return privateRequest.post(endPoint, data)
}

export const privatePut = (endPoint, id, data) => {
    return privateRequest.put(`${endPoint}/${id}`, data)
}

export const privatePatch = (endPoint, id, data) => {
    return privateRequest.put(`${endPoint}/${id}`, data)
}

export const privateDelete = (endPoint, id) => {
    return privateRequest.delete(`${endPoint}/${id}`)
}

export default privateRequest