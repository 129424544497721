import React from 'react';
import { Navigate} from 'react-router-dom';
import { getToken } from '../utils/utils';

const PrivateRoute = ({component: Component ,token, setToken}) => {
    return (
        getToken() ? <Component token={token} setToken={setToken} /> : <Navigate to="/"/>
    )
}
 
export default PrivateRoute;