import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Button, TextField, FormControl } from '@mui/material';
import Required from '../../shared/Required';
import './Client.css';
import { toast } from 'react-toastify';
import { privatePost, privatePut } from '../../../services/privateRequest';
import { CLIENTS } from '../../../services/apiEndpoints';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "#fff",
  borderRadius: "5px",
  boxShadow: 24,
  padding: "30px",
};

const AddClient = ({
  open,
  onClose,
  editClientData,
  onAddClient,
  handleEditClient,
  setFlag,
  edit,
  clientId,
  flag
}) => {
  const initialValues = {
    clientName: "",
  };

  const [postData, setPostData] = useState(initialValues);

  const handleChange = (event, key) => {
    setPostData({ ...postData, [key]: event.target.value });
  };

  const onSubmit = () => {
    const payload = {
      clientName: postData.clientName || editClientData.clientName,
    };

    if (clientId) {
      privatePut(CLIENTS, clientId, payload)
        .then((res) => {
          handleEditClient(res.data.data);
          toast.success(res.data.message);
          onClose();
          setPostData(initialValues)
          setFlag(!flag);
        })
        .catch((error) => {
          toast.error("Failed to update client");
          console.log('error', error);
        });
    } else {
      privatePost(CLIENTS, payload)
        .then((res) => {
          if (res && res.data) {
            onAddClient(res.data.data);
            toast.success(res.data.message);
            onClose();
            setPostData(initialValues)
            setFlag(true);
          }
        })
        .catch((error) => {
          toast.error("Failed to add client");
        });
    }
  };

  useEffect(() => {
    if (edit && editClientData) {
      setPostData({
        clientName: editClientData.clientName || "",
      });
    } else {
      setPostData(initialValues);
    }
  }, [edit, editClientData]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-title" variant="h6" component="h2">
          {edit ? "Edit" : 'Add New'} Client
        </Typography>

        <div className="form-container">
          <div style={{ display: "flex", flexDirection: "column", gap: "3px" }}>
            <p className="input-field-label">Client Name <Required /></p>
            <FormControl>
              <TextField
                size="small"
                variant="outlined"
                placeholder={"Enter Client Name"}
                multiline
                maxRows={10}
                InputProps={{
                  sx: {
                    borderRadius: "32px",
                    fontSize: "10px",
                    paddingLeft: "1rem",
                    backgroundColor: "white",
                  },
                }}
                fullWidth
                value={postData.clientName}
                onChange={(e) => handleChange(e, "clientName")}
              />
            </FormControl>
          </div>

          <div className="buttons-container">
            <Button
              onClick={onSubmit}
              disabled={!postData.clientName}
              variant="contained"
            >
              {edit ? "Update" : 'Add'} Client
            </Button>
            <Button
              onClick={onClose}
            >
              Close
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default AddClient;
